import React from 'react';

interface Props {
    className?: string;
}

const HomePageSection: React.FC<Props> = ({ children, className }) => (
  <section className={`max-w-7xl mx-auto py-12 px-6 lg:px-8 ${className}`}>
    {children}
  </section>
);

export default HomePageSection;
