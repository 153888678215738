import React from 'react';

interface Props {
  title: string,
  icon: React.ReactNode
}

const HowWeHelpCard: React.FC<Props> = ({ title, children, icon }) => (
  <div>
    <div className="bg-sky-500 h-12 w-12 rounded-md text-white p-3">
      {icon}
    </div>

    <p className="font-medium leading-6 text-lg my-3">
      {title}
    </p>

    <p className="text-gray-500">
      {children}
    </p>
  </div>
);

export default HowWeHelpCard;
