import React from 'react';
import HomePageSection from '../components/HomePageSection';
import SectionHeader from '../components/SectionHeader';
import PreHeader from '../components/PreHeader';
import FiveStars from '../components/FiveStars';

const ReviewSection: React.FC = () => (
  <HomePageSection>
    <PreHeader className="text-center">What our clients say</PreHeader>
    <SectionHeader className="text-center">Reviews</SectionHeader>
    <div className="max-w-2xl mx-auto py-8 text-center">
      <FiveStars />
      <p className="text-center text-2xl leading-relaxed mb-3">
        &ldquo;Good man. Knowledgeable, Professional, Responsive, Honest in the end.
        Handled a stressful situation with honest advice, for the fee he said he would.&rdquo;
      </p>
      <p className="text-center text-gray-500 font-semibold">Eliminated $20,000 in debt</p>
    </div>

    <div className="max-w-2xl mx-auto py-8">
      <FiveStars />
      <p className="text-center text-2xl leading-relaxed mb-3">
        &ldquo;Attorney Louis Chisari gave us a price.
        Stuck to it to the end including no extra charges
        for the finishing court appearances. Ethical, good guy.&rdquo;
      </p>
      <p className="text-center text-gray-500 font-semibold">Eliminated $40,000 in debt</p>
    </div>
  </HomePageSection>
);

export default ReviewSection;
