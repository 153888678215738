import { CreditCardIcon, PhoneMissedCallIcon, TrendingUpIcon } from '@heroicons/react/solid';
import React from 'react';
import HomePageSection from '../components/HomePageSection';
import HowWeHelpCard from '../components/HowWeHelpCard';
import PreHeader from '../components/PreHeader';
import SectionHeader from '../components/SectionHeader';

const HowWeHelpSection: React.FC = () => (
  <HomePageSection>
    <div className="text-center">
      <PreHeader>We can help</PreHeader>
      <SectionHeader>What&apos;s your challenge?</SectionHeader>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-6xl mx-auto">
      <div>
        <HowWeHelpCard title="Falling behind on bills?" icon={<CreditCardIcon className="text-white" />}>
          We can help reduce your credit card debt to a fraction
          of what you owe, get rid of late fees, and lower your interest rates.
        </HowWeHelpCard>
      </div>
      <div>
        <HowWeHelpCard title="Debt too much to handle?" icon={<TrendingUpIcon className="text-white" />}>
          We can help reduce your credit card debt to a fraction of what you owe,
          get rid of late fees, and lower your interest rates.
        </HowWeHelpCard>
      </div>
      <div>
        <HowWeHelpCard title="Collection calls unbearable?" icon={<PhoneMissedCallIcon className="text-white" />}>
          We can put a stop to debt collection calls through
          legal means such as written notices and cease and desist letters.
        </HowWeHelpCard>
      </div>
    </div>
  </HomePageSection>
);

export default HowWeHelpSection;
