import React from 'react';
import HomePageSection from '../components/HomePageSection';
import SectionHeader from '../components/SectionHeader';
import Faq from '../components/Faq';
import PreHeader from '../components/PreHeader';

const FaqSection: React.FC = () => (
  <HomePageSection className="bg-gray-50 rounded-md">
    <PreHeader className="text-center">More Questions?</PreHeader>
    <SectionHeader className="text-center">
      Frequently Asked Questions
    </SectionHeader>

    <div className="max-w-4xl mx-auto mt-8">
      <Faq question="How do I get started?">
        Getting started is as easy as picking up the phone and talking to one of our experts.
        Your path to financial freedom is just one toll-free phone call away!
        Call us today: 1-800-525-6199
      </Faq>

      <Faq question="How long does it take to complete the program?">
        We recognize that each individual’s situation is unique.
        The length of time needed to complete the program varies with each individual.
        The average client who sticks with their plan can complete the
        program in about 36-60 months, depending on the individual’s financial situation.
      </Faq>

      <Faq question="What kind of team will I be working with to resolve my debt?">
        From day one you will be working with a team of the industry’s
        leading experts in Debt Management and Bankruptcy.
        Our dedicated Attorneys and Credit Advisors will guide you through
        every step of the process and make sure you make informed
        decisions along the way to financial freedom.
      </Faq>

      <Faq question="How will your negotiated plan affect my credit?">
        That depends on what your credit looks like when you enrolled in our Debt Management Plan.
        In general, if you do not make payments to your creditors according to
        the terms of your agreement with them, your credit will suffer while you
        work through and recover from your financial difficulties
      </Faq>

      <Faq question="Does Debt Management Law offer credit repair services once I complete the program?">
        No, we do not offer credit repair services and we are not a credit repair company.
        There are other firms that specialize in assisting consumers in getting their
        credit back on track, in addition to providing other services
        that will improve your credit score over time.
      </Faq>

      <Faq question="Will I need to speak to creditors directly?">
        Yes, sometimes that will be necessary.
        Your active involvement in the process is very important.
        For difficult creditors or creditors who say they will not work
        with debt management companies, your participation may be required
        to a greater extent. There may be times when you will be required
        to actively participate in, or even directly communicate with, a difficult creditor.
      </Faq>

      <Faq question="Can Debt Management Law guarantee results?">
        No, we cannot guarantee the results of any particular negotiation
        or that any particular creditor will negotiate with us.
        We cannot force a creditor to accept a Debt Management Plan.
      </Faq>

      <Faq question="Should I avoid calls from collectors?">
        The decision to speak with your creditors is completely up to you.
        We contact your creditors during your initial coordination period
        using the appropriate negotiation method. Our services begin
        working for you immediately. We will continue to communicate
        with your creditors to finalize negotiations and your Debt Management Plan.
      </Faq>

      <Faq question="How does debt management work?">
        Consolidation is the process of taking all your credit card and personal loan
        debts and rolling them up into a single and more affordable monthly payment.
        This makes the management and payment of all your various unsecured debts
        far more convenient.
        <br />
        <br />
        What do we mean by secured debt? Any debt that is attached to
        something like a home or a car. Unsecured debts are things like
        credit cards, loans and medical bills. Here are a few examples:
        <br />
        <br />
        <b>Unsecured debt</b>
        <ol>
          <li>1. Credit cards</li>
          <li>2. Medical bills</li>
          <li>3. Personal loans</li>
        </ol>
        <br />
        <br />
        <b>Secured debt</b>
        <ol>
          <li>1. Mortgages</li>
          <li>2. Car loans</li>
        </ol>
      </Faq>

      <Faq question="What happens when I call?">
        Still wondering if our online service is right for you? Just give us a complimentary call.
        Our debt specialists will take a look at your finances to see
        how they can help you save.
        <br />
        <br />

        <b>Before calling in, make sure you have:</b>
        <ol>
          <li>1. Your most recent monthly credit card statement(s)</li>
          <li>2. Your monthly household income</li>
          <li>3. A list of your various monthly bills and obligations</li>
        </ol>
      </Faq>
    </div>

  </HomePageSection>
);

export default FaqSection;
