import React from 'react';
import ContactForm from '../components/ContactForm';
import HomePageSection from '../components/HomePageSection';
import PreHeader from '../components/PreHeader';
import SectionHeader from '../components/SectionHeader';

const GetStartedSection: React.FC = () => (
  <HomePageSection>
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="bg-gray-50 p-8">
        <SectionHeader>Do You Qualify For Debt Management?</SectionHeader>
        <PreHeader>Find Out Today</PreHeader>
        <p>
          The right solution for you will depend on your circumstances.
          At Debt Management Law, we help our clients explore all of their alternatives.
          We want you to understand the choices available to you so you can
          make a choice that is right for you.
        </p>

        <div className="mt-4">
          <p className="font-semibold">Have more questions first?</p>
          <a href="tel:1-800-525-6199">
            <p>Call us at 1-800-525-6199</p>
          </a>

        </div>
      </div>
      <div className="col-span-2 p-8">
        <ContactForm />
      </div>
    </div>
  </HomePageSection>
);

export default GetStartedSection;
