import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { StaticImage } from 'gatsby-plugin-image';
import Button from '../components/Button';
import CurvedArrow from '../components/icons/CurvedArrow';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const HeroSection: React.FC = () => (
  <section className="min-h-0 bg-gray-50 mx-0 py-12">
    <div className="grid grid-cols-1 md:grid-cols-2 w-full items-center">
      <div className="text-left px-12 pb-12 md:pb-0 md:mx-auto">
        <h1 className="tracking-tight font-extrabold text-sky-900 text-4xl lg:text-6xl">
          <span className="block">Stop Surviving,</span>
          <span className="block text-sky-500">Start Living!</span>
        </h1>
        <p className="text-xl my-5">Do you qualify for debt management?</p>
        <div className="flex">
          <a href="#get-started" className="no-underline">
            <Button>
              Find Out In 30 Seconds
            </Button>
          </a>
          <CurvedArrow className="w-12 h-12 -mt-3 ml-1 text-gray-600" />
        </div>
      </div>
      <div className="relative h-full flex items-center">
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg z-40 bg-gradient-to-r from-gray-50 via-transparent to-gray-50" />
        <Carousel showIndicators={false} showThumbs={false} showArrows={false} autoPlay infiniteLoop dynamicHeight centerMode showStatus={false} className="z-0 w-full h-full">
          <div className="h-full w-full">
            <StaticImage
              src="../images/couple-bike.jpg"
              alt="Carousel"
              layout="fullWidth"
              aspectRatio={3 / 2}
              placeholder="blurred"
            />
          </div>
          <div className="h-full w-full">
            <StaticImage
              src="../images/lawyer-high-five.jpeg"
              alt="Carousel"
              layout="fullWidth"
              aspectRatio={3 / 2}
              placeholder="blurred"
            />
          </div>
          <div className="h-full w-full">
            <StaticImage
              src="../images/woman-phone.jpg"
              alt="Carousel"
              layout="fullWidth"
              aspectRatio={3 / 2}
              placeholder="blurred"
            />
          </div>
        </Carousel>
      </div>
    </div>
  </section>
);

export default HeroSection;
