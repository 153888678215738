import React from 'react';
import { Disclosure } from '@headlessui/react';
import HomePageSection from '../components/HomePageSection';
import PracticeAreaCard from '../components/PracticeAreaCard';
import SectionHeader from '../components/SectionHeader';
import PreHeader from '../components/PreHeader';
import Button from '../components/Button';

const PracticeAreaSection = () => (
  <HomePageSection>
    <PreHeader className="text-center">What We Do</PreHeader>
    <SectionHeader className="text-center">Practice Areas</SectionHeader>
    <PracticeAreaCard title="Bankruptcy" image="/images/credit-squeeze-g46dde6d1f_640.jpg">
      We truly listen to your needs and thoroughly analyze
      your case to determine if bankruptcy offers the most
      effective way to ease your financial burden.
      We review your debts, income and expenses,
      and explain the ultimate impact bankruptcy would have on your family,
      business, home, and future credit.
    </PracticeAreaCard>
    <PracticeAreaCard title="Debt Management" image="/images/man-gd7fa3ea32_640.jpg">
      By using The Chisari Law Group for Debt Management
      you are represented by a licensed attorney.
      If you need help settling your debts, or are unsure
      about whether negotiating settlements is appropriate,
      one of our skilled attorneys can provide you with practical
      legal advice after fully analyzing your situation.
    </PracticeAreaCard>
    <PracticeAreaCard title="Debt Settlement" image="/images/bookkeeping-g975e85bb6_640.jpg">
      Debt Settlement helps you negotiate what you owe into
      something you can afford to pay.
      This debt solution allows you – or a third-party settlement company –
      to pay your lender in full at a lower cost than your original amount owed.
      This single, lump-sum payment will help you eliminate your debt faster.
    </PracticeAreaCard>

    <Disclosure>
      <div className="text-center">
        <Disclosure.Button>
          <Button outline>See More Areas We Can Help With</Button>
        </Disclosure.Button>
      </div>
      <Disclosure.Panel>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-2">
          <PracticeAreaCard small title="Foreclosures" image="/images/foreclosure.png">
            Hiring an attorney early in the process helps ensure that you
            have more options available to you than what might be available
            later in the foreclosure process. A lawyer can, for instance,
            help you work out a deal with the lender that will allow you
            to stay in the home, or, if necessary, fight the foreclosure in court.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Repossessions" image="/images/repossession.png">
            We will review your case to check for any violation of your rights and
            determine your best course of action. We act promptly and aggressively
            in order to protect your interests. If no repossession notice is supplied or
            it does not contain all required clauses, the creditor may not have the
            right to collect money from you.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Medical Provider Collections" image="/images/medical-provider-collections.png">
            Let our experts do the work –
            organizing and analyzing your medical bills and taking the fight to
            hospitals and insurance companies. We can maximize your savings.
            Hospitals charge patients on average 3.5x what they charge insurance companies.
            We know what fair rates are and how to get them.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Criminal Defense" image="/images/criminal-defense.png">
            If you are accused of breaking a federal law,
            you will be tried at the federal level.
            Crimes against federal agencies such as the SEC (e.g., securities fraud),
            U.S. Treasury (e.g., counterfeiting), EPA (e.g., toxic waste dumping),
            ATF (e.g., gun-running) and DEA (e.g., drug smuggling) are federal crimes.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Liquor Licensing" image="/images/liquor-licensing.png">
            Your business must meet specific requirements to be issued a liquor license.
            We meticulously prepare your application and closely monitor
            the process until you have received the necessary licenses and permits.
            We foresee potential issues and help you rectify problems to
            prevent delays or denials.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Wills &amp; Trusts" image="/images/wills-trusts.png">
            Wills and trusts are the principal legal instruments
            attorneys use to help clients transfer assets to their heirs.
            Our experienced attorneys can advise you on the best means for you to
            address your legacy concerns, including the transfer of financial
            and real property assets, estate tax avoidance, and guardianships.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Divorce" image="/images/divorce.png">
            The dissolution of your marriage is an emotional and stressful event.
            Our composed, compassionate attorneys help ease the legal strain of
            divorce so you can concentrate on the health and well-being of your family.
            We take your individual needs into account as we seek the best possible results for you.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Real Estate" image="/images/real-estate.png">
            Whether you are buying a home, negotiating an office build-out or
            litigating an easement dispute, our attorneys at are available to help.
            Utilizing our extensive knowledge about property rights, real estate issues,
            finance and business, we stand by your side in all important real estate issues.
          </PracticeAreaCard>

          <PracticeAreaCard small title="Insurance" image="/images/insurance.png">
            Our attorneys understand all sides of insurance issues,
            having represented policyholders, third-party claimants and major insurance carriers.
            Whether you need advice on policy terms, are seeking payment for medical costs in
            a no-fault case, or are being denied the coverage you’re entitled to,
            we’ll give you seasoned advice.
          </PracticeAreaCard>
        </div>
      </Disclosure.Panel>
    </Disclosure>

  </HomePageSection>
);

export default PracticeAreaSection;
