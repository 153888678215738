import React from 'react';
import SectionHeader from './SectionHeader';

interface Props {
    title: string;
    image: string;
    small?: boolean;
}

const PracticeAreaCard: React.FC<Props> = ({
  title, image, small, children,
}) => (
  <div className={`grid grid-cols-1 ${!small ? 'md:grid-cols-2' : ''} p-8 bg-gray-50 my-5 rounded-md`}>
    <div>
      <SectionHeader className="text-xl">{title}</SectionHeader>
      <p className="leading-relaxed text-gray-500">{children}</p>
    </div>
    <div className={`flex justify-center md:px-8 py-4 ${!small ? 'md:py-0' : ''}`}>
      <img src={image} alt={title} className="rounded-md shadow w-full h-full object-cover" />
    </div>
  </div>
);

export default PracticeAreaCard;
