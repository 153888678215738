import { Listbox, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import states from '../data/states';
import Button from './Button';
import InputLabel from './InputLabel';
import TextInput from './TextInput';

const ContactFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .min(2)
    .max(50)
    .required(),
  lastName: Yup.string()
    .label('Last name')
    .min(2)
    .max(50)
    .required(),
  email: Yup.string()
    .label('Email')
    .email()
    .required(),
  phone: Yup.string()
    .label('Phone number')
    .min(7)
    .max(11)
    .required(),
  state: Yup.string()
    .label('State')
    .oneOf(states.map((state) => state.name))
    .required(),
  debtAmount: Yup.string()
    .label('Debt amount')
    .required(),
});

const ContactForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isErrored, setIsErrored] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const reloadPage = React.useCallback(() => {
    window.location.reload();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      debtAmount: '',
      state: '',
    },
    validationSchema: ContactFormSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const token = await executeRecaptcha('submit');
        await axios({
          method: 'POST',
          url: process.env.GATSBY_CONTACT_FORM_ENDPOINT,
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phone,
            state: values.state,
            debtAmount: values.debtAmount,
            recaptchaToken: token,
          },
        });
        setIsSubmitted(true);
      } catch {
        setIsErrored(true);
      }
    },
  });

  if (isSubmitted) {
    return (
      <div className="md:px-12">
        <p className="font-bold text-xl mb-2">Thank you!</p>
        <p>One of our debt specialists will be contacting you shortly.</p>
      </div>
    );
  }

  if (isErrored) {
    return (
      <div className="md:px-12">
        <p className="font-bold text-xl mb-2">Oops!</p>
        <p className="mb-3">
          There was a problem submitting your response.
          We&apos;re sorry for any inconvenience this may have caused.
          Please reload the page and try again.
        </p>
        <Button onClick={reloadPage}>Reload Now</Button>
      </div>
    );
  }

  return (
    <div>
      <div className="md:px-12">
        <TextInput
          label="First name"
          name="firstName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.touched.firstName && formik.errors.firstName}
          disabled={formik.isSubmitting}
        />
        <TextInput
          label="Last name"
          name="lastName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.touched.lastName && formik.errors.lastName}
          disabled={formik.isSubmitting}
        />
        <TextInput
          label="Email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          type="email"
          error={formik.touched.email && formik.errors.email}
          disabled={formik.isSubmitting}
        />
        <TextInput
          label="Phone number"
          name="phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          error={formik.touched.phone && formik.errors.phone}
          type="tel"
          disabled={formik.isSubmitting}
        />
        <div className="my-2">
          <InputLabel>State</InputLabel>
          <Listbox disabled={formik.isSubmitting} value={formik.values.state} onChange={(value) => formik.setFieldValue('state', value)}>
            <div className="relative mt-2">
              <Listbox.Button className={`w-full flex justify-between items-center bg-white border ${formik.touched.state && formik.errors.state ? 'border-red-500' : 'border-gray-300'}  rounded-md shadow-sm px-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500`}>
                <span className="sr-only">Choose a state</span>
                <span className="block truncate">{formik.values.state}</span>
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </Listbox.Button>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {states.map((state) => (
                    <Listbox.Option
                      key={state.abbreviation}
                      className={({ active }) => `${active ? 'text-sky-900 bg-sky-100' : 'text-gray-900'}
                          cursor-default select-none relative py-2 px-10`}
                      value={state.name}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`${
                              selected ? 'font-medium' : 'font-normal'
                            } block truncate`}
                          >
                            {state.name}
                          </span>
                          {selected ? (
                            <span
                              className="text-sky-600 absolute inset-y-0 left-0 flex items-center pl-3"
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
          <p className="text-sm text-red-500">
            {formik.touched.state && formik.errors.state}
            {(!formik.touched.state || !formik.errors.state) && <>&nbsp;</>}
          </p>
        </div>

        <div className="my-2">
          <InputLabel>Approximate debt amount</InputLabel>
          <label htmlFor="4999" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="4999" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$1-$4,999" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$1-$4,999</span>
          </label>

          <label htmlFor="9999" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="9999" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$5,000-$9,999" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$5,000-$9,999</span>
          </label>

          <label htmlFor="24999" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="24999" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$10,000-$24,999" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$10,000-$24,999</span>
          </label>

          <label htmlFor="49999" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="49999" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$25,000-$49,999" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$25,000-$49,999</span>
          </label>

          <label htmlFor="99999" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="99999" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$50,000-$99,999" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$50,000-$99,999</span>
          </label>

          <label htmlFor="100000" className="block mb-2 text-gray-700">
            <input disabled={formik.isSubmitting} type="radio" id="100000" className="w-4 h-4 form-radio text-sky-500" name="debtAmount" value="$100,000+" onChange={formik.handleChange} onBlur={formik.handleBlur} />
            <span className="ml-2">$100,000+</span>
          </label>
          <p className="text-sm text-red-500">
            {formik.touched.debtAmount && formik.errors.debtAmount}
            {(!formik.touched.debtAmount || !formik.errors.debtAmount) && <>&nbsp;</>}
          </p>
        </div>

        <Button
          onClick={() => formik.handleSubmit()}
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          Click Here To See If You Qualify
        </Button>
      </div>
    </div>
  );
};

export default ContactForm;
