import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import HomePageSection from '../components/HomePageSection';
import SectionHeader from '../components/SectionHeader';

const LeadershipSection: React.FC = () => (
  <HomePageSection>
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="px-8 py-8 md:py-0">
        <SectionHeader>Our Leadership</SectionHeader>
        <p className="text-base text-gray-500 leading-relaxed">
          Based in New York, our team has over twenty years of experience in the field of law.
          We specialize in providing debt management plans for unsecured
          debt as well as bankruptcy advice and service.
        </p>
      </div>
      <div className="px-8 col-span-2 grid grid-cols-1 md:grid-cols-3">
        <div className="w-full h-full">
          <StaticImage
            src="../images/louis-pictures/IMG_1764.jpg"
            alt="Louis F. Chisari"
            className="rounded-md shadow h-full"
            layout="fullWidth"
            transformOptions={{ fit: 'outside' }}
            placeholder="blurred"
          />
        </div>
        <div className="px-6 col-span-2 pt-12 md:pt-0">
          <p className="font-bold">
            Louis F. Chisari,
            {' '}
            <span className="text-sky-500">Esq.</span>
          </p>
          <p className="leading-relaxed text-gray-500 text-base">
            Louis F. Chisari is a New York based Bankruptcy Attorney
            with nearly 20 years&apos; experience in the practice area
            of consumer and business financial debt-related services.
            He is highly accomplished in negotiation and settlements and
            has gone up against large national banks, credit unions,
            collection agencies and multiple creditor legal representatives.
            Most recently, Louis has been recognized by Continental Who&apos;s Who
            as a Top Attorney in the field of law in recognition of his outstanding work.
            Mr. Chisari is revered for his outstanding contributions to the legal profession.
            Throughout his career, he has held several prestigious positions at
            the Office of the County Attorney, County of Nassau such as Deputy County Attorney
            from 1998 to 2003, Staff Counsel at Geico 2003 to 2006, and Associate at Nicolini,
            Paradise, Ferretti and Sabella from 2006 to 2007. Throughout his illustrious career,
            he has attained extensive experience in the areas of Bankruptcy,
            Criminal and Family law.
          </p>
        </div>
      </div>
    </div>
  </HomePageSection>
);

export default LeadershipSection;
