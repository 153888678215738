import { StarIcon } from '@heroicons/react/solid';
import React from 'react';

const FiveStars: React.FC = () => (
  <div className="flex items-center justify-center">
    <StarIcon className="w-4 h-4 text-sky-400" />
    <StarIcon className="w-4 h-4 text-sky-400" />
    <StarIcon className="w-4 h-4 text-sky-400" />
    <StarIcon className="w-4 h-4 text-sky-400" />
    <StarIcon className="w-4 h-4 text-sky-400" />
  </div>
);

export default FiveStars;
