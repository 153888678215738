/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const query = graphql`
    query SiteMetaData {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;

const Seo: React.FC = () => {
  const { site } = useStaticQuery(query);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>{site.siteMetadata.title}</title>
      <meta name="description" content={site.siteMetadata.description} />
    </Helmet>
  );
};

export default Seo;
