import React from 'react';

interface Props {
    className?: string;
}

const CurvedArrow: React.FC<Props> = ({ className }) => (
  <svg className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.75 106.25">
    <path d="M1.949,75.078l20.806,9.611c1.684,0.777,3.678,0.043,4.455-1.64c0.776-1.684,0.043-3.677-1.64-4.455v0.001l-13.874-6.41  c19.145-4.849,32.377-12.89,41.376-21.896C63.655,39.71,68.319,27.918,70.306,18.825c1.99-9.106,1.352-15.558,1.33-15.806  c-0.188-1.844-1.834-3.188-3.678-3.002c-0.801,0.081-1.507,0.438-2.034,0.965c-0.688,0.688-1.073,1.67-0.968,2.713l0.001,0.001  c0,0,0,0,0.001,0.022c0.045,0.479,0.495,6.402-1.384,14.442c-1.884,8.05-6.048,18.178-15.249,27.381  c-8.112,8.105-20.193,15.574-38.43,20.174l6.188-13.689c0.764-1.689,0.014-3.679-1.676-4.442c-1.31-0.591-2.799-0.273-3.758,0.687  c-0.278,0.278-0.512,0.61-0.685,0.991L0.298,70.649C-0.46,72.329,0.277,74.306,1.949,75.078z" />
  </svg>
);

export default CurvedArrow;
