import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface Props {
    question: string
}

const Faq: React.FC<Props> = ({ question, children }) => (
  <div className="">
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full border-t py-5 flex items-center justify-between">
            <p className="font-bold text-left">{question}</p>
            <ChevronDownIcon className={`w-8 h-8 text-gray-500 transition-transform duration-300 transform ${open ? 'rotate-180' : ''}`} />
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500 py-4">
            <p>{children}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

  </div>
);

export default Faq;
