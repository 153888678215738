import React from 'react';
import InputLabel from './InputLabel';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: string;
}

const TextInput: React.FC<Props> = ({ label, error, ...rest }) => (
  <div className="mb-2">
    <label htmlFor={label}>
      <InputLabel>{label}</InputLabel>
      <input
        {...rest}
        id={label}
        className={`w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 ${error ? 'border-red-500' : ''}`}
      />
      <p className="text-sm text-red-500">
        {error}
        {!error && <>&nbsp;</>}
      </p>
    </label>
  </div>
);

export default TextInput;
