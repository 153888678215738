import React from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import MainLayout from '../layouts/MainLayout';
import Button from '../components/Button';
import HomePageSection from '../components/HomePageSection';
import SectionHeader from '../components/SectionHeader';
import PracticeAreaSection from '../sections/PracticeAreaSection';
import HeroSection from '../sections/HeroSection';
import FaqSection from '../sections/FaqSection';
import HowWeHelpSection from '../sections/HowWeHelpSection';
import LeadershipSection from '../sections/LeadershipSection';
import ReviewSection from '../sections/ReviewSection';
import GetStartedSection from '../sections/GetStartedSection';
import Seo from '../components/Seo';

// Offset all anchors by -60 to account for a fixed header
// and scroll slower than the default 400ms
configureAnchors({ offset: -120, scrollDuration: 1000 });

const IndexPage: React.FC = () => (
  <>
    <Seo />
    <MainLayout>
      <HeroSection />

      <HowWeHelpSection />

      <HomePageSection className="bg-gray-50">
        <div className="text-center">
          <SectionHeader>Why choose a debt management lawyer?</SectionHeader>
          <p className="max-w-3xl mx-auto my-4 leading-relaxed">
            By using Debt Management Law you are represented by a licensed attorney.
            If you need help consolidating your debts, or are unsure about
            whether negotiating settlements is appropriate, one of our
            knowledgeable attorneys can provide you with practical legal advice after fully
            analyzing your situation.

            Our negotiation skills developed over many years of
            practical experience, as well as extensive
            knowledge about debt collections make choosing us better than hiring a debt settlement
            company or trying to do it yourself. Our lawyers can also
            represent you if a creditor files
            lawsuit. Debt settlement companies can&apos;t do these things.
          </p>
          <a href="#get-started">
            <Button>Get Started</Button>
          </a>
        </div>
      </HomePageSection>

      <LeadershipSection />

      <PracticeAreaSection />

      <ReviewSection />

      <FaqSection />

      <ScrollableAnchor id="get-started">
        <div>
          <GetStartedSection />
        </div>
      </ScrollableAnchor>

    </MainLayout>
  </>
);

export default IndexPage;
